import React from "react";
import "./LoadingScreen.css";

export const LoadingScreen = () => {
    return (
        <div className="spinner-container">
            <div className="spinner"></div>
        </div>

    );
};